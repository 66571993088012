import * as React from "react";
import Untitled from "../../assets/images/Untitled.png";

const Map = () => {
  return (
    <div style={{ height: "40vh", width: "100%" }}>
      <img alt="Map-block" src={Untitled} />
    </div>
  );
};

export default Map;
