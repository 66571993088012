import { Typography } from "@mui/material";

const FlagBanner = (props) => {
  return (
    <Typography
      component={props.notMain ? "h2" : "h1"}
      variant="h1"
      className="bigtext"
    >
      Mzansi Taal
    </Typography>
  );
};

export default FlagBanner;

// <h1 className="bigtext">Mzansi Taal</h1>;
