import { configureStore } from "@reduxjs/toolkit";
import terms from './reducers/termsSlice';
import userReducer from './reducers/userSlice';

const store = configureStore({
    reducer: {
        user: userReducer,
        terms: terms.reducer
    }
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;