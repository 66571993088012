import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SocialLinks from "../ui/SocialLinks";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import { logout, selectUser, login } from "../../redux/reducers/userSlice";
import {
  auth,
  getExtraUserInformation,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "../../firebase";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  const [state, setState] = React.useState({
    email: "",
    password: "",
  });

  // check at page load if a user is authenticated
  useEffect(() => {
    onAuthStateChanged(auth, async (userAuth) => {
      if (userAuth) {
        const values = await getExtraUserInformation(userAuth.uid)

        dispatch(
          login({
            email: userAuth.email,
            uid: userAuth.uid,
            displayName: userAuth.displayName,
            photoUrl: userAuth.photoURL,
            roles: values?.roles,
          })
        );
      }
    });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = () => {
    // Sign in an existing user with Firebase
    signInWithEmailAndPassword(auth, state.email, state.password)
      // returns  an auth object after a successful authentication
      // userAuth.user contains all our user details
      .then((userAuth) => {
        // store the user's information in the redux state
        dispatch(
          login({
            email: userAuth.user.email,
            uid: userAuth.user.uid,
            displayName: userAuth.user.displayName,
          })
        );
      })
      .then(() => {
        navigate('/', { replace: true })
      })
      // display the error if any
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <React.Fragment>
      <Grid sx={{ flexGrow: 1 }} container justifyContent="center">
        <CardContent>
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center">
              <Typography
                component="h1"
                variant="h3"
                color="primary.main"
                gutterBottom
                textAlign="center"
              >
                <strong>Log In</strong>
              </Typography>
            </Grid>
          </Grid>
          <br />
          <TextField
            margin="normal"
            variant="outlined"
            id="email"
            placeholder="Email Address"
            name="email"
            type="email"
            fullWidth
            autoFocus
            style={{ backgroundColor: "white" }}
            value={state.email}
            onChange={handleInputChange}
          />
          <TextField
            margin="normal"
            variant="outlined"
            id="password"
            placeholder="Password"
            name="password"
            type="password"
            fullWidth
            style={{ backgroundColor: "white" }}
            value={state.password}
            onChange={handleInputChange}
          />
          <Stack direction="column">
            <Grid container item justifyContent="center">
              <Button
                variant="outlined"
                onClick={() => handleSubmit()}
                sx={{ m: 2, width: "50%" }}
              >
                Log In
              </Button>
            </Grid>
            <Grid container item justifyContent="center">
              <Button variant="text" color="info">
                Forgot Password
              </Button>
              <Button variant="text" color="info">
                Register
              </Button>
            </Grid>
            <SocialLinks />
          </Stack>
        </CardContent>
      </Grid>
      <br />
    </React.Fragment>
  );
};

export default LoginForm;
