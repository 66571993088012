import * as React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import FlagBanner from "../components/ui/FlagBanner";
import ImageButtons from "../components/ui/ImageButtons";
import Botswana from "../assets/images/botswana.png";
import Namibia from "../assets/images/namibia.png";
import South_Africa from "../assets/images/southafrica.png";
import Zambia from "../assets/images/zambia.png";
import Zimbabwe from "../assets/images/zimbabwe.png";
import theme from "../theme/theme";

export type CountryProps = {
  title: string;
  url: string;
  img: string;
};

const countries: CountryProps[] = [
  {
    title: "Botswana",
    url: "https://botswana.glcorp.org/",
    img: Botswana,
  },
  {
    title: "Namibia",
    url: "https://namibia.glcorp.org/",
    img: Namibia,
  },
  { title: "South Africa", url: "/", img: South_Africa },
  {
    title: "Zambia",
    url: "https://zambia.glcorp.org/",
    img: Zambia,
  },
  {
    title: "Zimbabwe",
    url: "https://zimbabwe.glcorp.org/",
    img: Zimbabwe,
  },
];

const Countries = (props) => {
  const {
    breakpoints,
    typography: { pxToRem },
  } = theme;

  return (
    <React.Fragment>
      <Grid container justifyContent="center">
        <FlagBanner />
      </Grid>
      <article>
        <Card
          sx={{
            marginBottom: 10,
            marginX: 10,
          }}
        >
          <CardContent>
            <Grid container justifyContent="center">
              <Grid item container justifyContent="center">
                <Typography
                  component="h2"
                  variant="h4"
                  color="primary.main"
                  gutterBottom
                  textAlign="center"
                  sx={{
                    fontSize: { fontSize: 24 },
                    [breakpoints.up("xl")]: {
                      fontSize: 96,
                    },
                  }}
                >
                  <strong>Select A Country</strong>
                </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                sm={12}
                md={9}
                lg={9}
              >
                {countries.map((c) => (
                  <ImageButtons
                    key={c.title}
                    title={c.title}
                    url={c.url}
                    img={c.img}
                  />
                ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </article>
    </React.Fragment>
  );
};

export default Countries;
