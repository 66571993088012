import { Grid, IconButton } from "@mui/material";
import { Facebook, LinkedIn, Twitter } from "@mui/icons-material";

const SocialLinks = () => {
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item container justifyContent="center">
        <IconButton size="large" color="primary" aria-label="Facebook link">
          <Facebook />
        </IconButton>
        <IconButton size="large" color="primary" aria-label="Twitter link">
          <Twitter />
        </IconButton>
        <IconButton size="large" color="primary" aria-label="LinkedIn link">
          <LinkedIn />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default SocialLinks;
