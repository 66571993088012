import React, { useEffect, useState } from "react";
import FlagBanner from "../components/ui/FlagBanner";
import { Grid, Typography, Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../redux/hooks/hooks";
// import { getTerms } from "../redux/reducers/termsSlice";
import SearchBar from "../components/ui/SearchBar";
import AdSense from "react-adsense";
import { TermType } from "../types/types";
import { getTerms } from "../redux/reducers/termsSlice";

const Terms = () => {
  const dispatch = useAppDispatch();
  const terms = useAppSelector((state) => state.terms.terms);

  useEffect(() => {
    dispatch(getTerms());
  }, []);

  return (
    <>
      <Grid container justifyContent={"center"}>
        <FlagBanner />
      </Grid>
      <Grid container justifyContent={"center"}>
        <AdSense.Google
          client="ca-pub-1901398323502922"
          slot="6262655829"
          adtest
          format="horizontal,auto"
          style={{ width: 500, height: 300, float: "left" }}
        />
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-1901398323502922"
          data-ad-slot="6262655829"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
      </Grid>
      <Grid container justifyContent={"center"}>
        <SearchBar />
      </Grid>
      <Grid container justifyContent={"center"}>
        <Typography variant={"h4"}>Filters go here</Typography>
      </Grid>
      <Grid container justifyContent={"center"}>
       {terms.map((term: TermType) => <Button variant="outlined" href={`/terms/${term.term}`}>{term.term}</Button>)}
      </Grid>
    </>
  );
};

export default Terms;


// {terms.map((term) => (
//   <div dangerouslySetInnerHTML={{ __html: term.editorContent }}></div>
// ))}