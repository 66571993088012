//src/firebase.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import {
  doc,
  setDoc,
  collection,
  getDoc,
  getFirestore,
  getDocs,
} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// your firebase config here

const firebaseConfig = {
  apiKey: "AIzaSyBqC8L67vFSCsnsMwVBOsdN5dqO9yGvmoU",
  authDomain: "mtreact-demo.firebaseapp.com",
  projectId: "mtreact-demo",
  storageBucket: "mtreact-demo.appspot.com",
  messagingSenderId: "692774908578",
  appId: "1:692774908578:web:372a5e82a23ee188a65dfb",
  measurementId: "G-YK2M43NRPH",
};

//init firebase app
const app = initializeApp(firebaseConfig);

//init services
const auth = getAuth();
const storage = getStorage(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export const createUserProps = async (uid, roles) => {
  await setDoc(doc(db, "users", uid), {
    roles,
  }).then((res) => res);
};

export const getExtraUserInformation = async (uid) => {
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
};

export const createTerm = async (term) => {
  await setDoc(doc(db, "terms", term.term), {
    ...term
  }).then((res) => res);
};

export const fetchTerms = async () => {
  const querySnapshot = await getDocs(collection(db, "terms"));
  const termArray = [];
  querySnapshot.forEach((doc) => {
    termArray.push(doc.data());
  });
  return termArray;
};

export const fetchSingleTerm = async (term) => {
  const docRef = doc(db, "terms", term);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return {};
    console.log("No such document!");
  }
};

export {
  auth,
  storage,
  db,
  analytics,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
};
