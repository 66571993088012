import * as React from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import SocialLinks from "../components/ui/SocialLinks";
import FlagBanner from "../components/ui/FlagBanner";
import Video from "../components/ui/Video";
import Map from "../components/ui/Map";

const About = () => {
  const commonStyles = {
    bgcolor: "secondary.main",
    pt: 1,
    m: 1,
    width: "65%",
  };

  return (
    <React.Fragment>
      <Grid container justifyContent="center">
        <FlagBanner />
      </Grid>
      <article>
        <Card
          variant="outlined"
          sx={{
            marginBottom: 10,
            marginX: 20,
            backgroundColor: "secondary.main",
          }}
        >
          <CardContent>
            <Grid container justifyContent="center">
              <Grid
                item
                container
                justifyContent="center"
                sx={{ ...commonStyles }}
              >
                <Typography
                  component="h2"
                  variant="h4"
                  color="primary.main"
                  gutterBottom
                  textAlign="center"
                >
                  <strong>Honours</strong>
                </Typography>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  color="primary.main"
                  textAlign="center"
                  sx={{
                    fontStyle: "italic",
                  }}
                >
                  Best Digital Humanities Collection: Community Engagement at
                  the
                  <Button
                    sx={{
                      color: "info.main",
                      typography: "body2",
                      fontStyle: "italic",
                    }}
                    variant="text"
                  >
                    HSS Awards 2022
                  </Button>
                </Typography>
                <Grid item md={12}>
                  <Video embedId="wOjjjDKjCr0" />
                </Grid>
                <Typography
                  component="h2"
                  variant="h4"
                  color="primary.main"
                  gutterBottom
                  textAlign="center"
                >
                  <strong>What is Mzanzi Taal?</strong>
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  color="primary.main"
                  textAlign="center"
                >
                  <Button
                    sx={{ color: "info.main", typography: "body2" }}
                    variant="text"
                  >
                    Mzansi
                  </Button>
                  (n) – means South Africa.
                  <br />
                  <br />
                  Taal (n) – means language.
                  <br />
                  <br />
                  Mzansi Taal is a South African general lingo dictionary for
                  Tsotsi Taal, Spitori,
                  <br />
                  <Button
                    sx={{ color: "info.main", typography: "body2" }}
                    variant="text"
                  >
                    Ringas,
                  </Button>
                  Scamtho,
                  <Button
                    sx={{ color: "info.main", typography: "body2" }}
                    variant="text"
                  >
                    Afrikaaps
                  </Button>
                  and more. New words and phrases are researched and
                  <br />
                  published as they arise.
                  <br />
                  <br />
                  “We do not create words/phrases, but rather capture language
                  in the way that it is
                  <br />
                  being spoken or written by the users.”
                </Typography>
                <Grid item md={12}>
                  <Video embedId="i1SAHgBQVu4" />
                </Grid>
                <br />
                <Typography
                  component="h2"
                  variant="h4"
                  color="primary.main"
                  gutterBottom
                  textAlign="center"
                >
                  <strong>Mission and Objectives</strong>
                </Typography>
                <Typography
                  component="ul"
                  gutterBottom
                  variant="body1"
                  color="primary.main"
                  textAlign="center"
                >
                  <li>
                    To educate the user about the various lingo terms and
                    phrases that are
                    <br />
                    widely used in South Africa.
                  </li>
                  <li>To contribute to research in language.</li>
                  <li>
                    To remain as recent, accurate and as credible as possible.
                  </li>
                </Typography>
                <Typography
                  component="h2"
                  variant="h4"
                  color="primary.main"
                  gutterBottom
                  textAlign="center"
                >
                  <strong>Why We Do What We Do?</strong>
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  color="primary.main"
                  textAlign="center"
                >
                  “Multilingualism, Code-switching, Digital and Social Media is
                  shaping a new
                  <br />
                  vehicular language.”
                  <br />
                  <br />
                  Language is developing at a rapid rate because of
                  multilingualism and new age
                  <br />
                  technology such as digital and social media. This has brought
                  about a large
                  <br />
                  vocabulary of thousands of words and phrases used everyday by
                  millions of South
                  <br />
                  Africans in various communities across the country. A large
                  number of these words
                  <br />
                  and phrases have not yet been captured.
                  <br />
                  <br />
                  In order to stay ahead it is essential for language research
                  to keep track of these
                  <br />
                  changes as they arise.
                  <br />
                  <br />
                  Mzansi Taal exists to close that gap.
                  <br />
                </Typography>
                <Typography
                  component="h2"
                  variant="h4"
                  color="primary.main"
                  gutterBottom
                  textAlign="center"
                >
                  <strong>
                    UNISA Registered Open Educational
                    <br />
                    Resource
                  </strong>
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  color="primary.main"
                  textAlign="center"
                >
                  UNISA Institutional Repository:
                  <Button
                    sx={{ color: "info.main", typography: "body2" }}
                    variant="text"
                    href="http://uir.unisa.ac.za/handle/10500/27823"
                    target="_blank"
                  >
                    http://uir.unisa.ac.za/handle/10500/27823
                  </Button>
                </Typography>
                <Typography
                  component="h2"
                  variant="h4"
                  color="primary.main"
                  gutterBottom
                  textAlign="center"
                >
                  <strong>How To Use Mzansi Taal?</strong>
                </Typography>
                <Grid item md={12}>
                  <Video embedId="GhaIkAZoJMg" />
                </Grid>
                <br />
                <br />
                <Typography
                  component="h2"
                  variant="h4"
                  color="primary.main"
                  gutterBottom
                  textAlign="center"
                >
                  <strong>How You Can Contribute</strong>
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  color="primary.main"
                  textAlign="center"
                >
                  You can
                  <Button
                    sx={{ color: "info.main", typography: "body2" }}
                    variant="text"
                    href="/authentication"
                  >
                    sign-up, log-in
                  </Button>
                  and start suggesting terms and phrases that you think
                  <br />
                  should be published on the dictionary. Our editors will
                  moderate the terms and
                  <br />
                  publish it if it meets the criteria.
                  <br />
                  <br />
                  You may also share and post comments on various words/phrases.
                  <br />
                  <br />
                </Typography>
                <Typography
                  component="h2"
                  variant="h4"
                  color="primary.main"
                  gutterBottom
                  textAlign="center"
                >
                  <strong>Contact Details</strong>
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  color="primary.main"
                  textAlign="center"
                >
                  If you would like to talk to us for any suggestions, marketing
                  related stuff or more
                  <br />
                  feel free to send us an email to the address below and we will
                  get back to you as
                  <br />
                  soon as possible.
                  <br />
                  <br />
                  <strong>Email: </strong>
                  Info@mzansitaal.co.za
                </Typography>
                <Grid item md={12}>
                  <br />
                  <Typography
                    component="h2"
                    variant="h4"
                    color="primary.main"
                    gutterBottom
                    textAlign="center"
                  >
                    <strong>Location</strong>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="primary.main"
                    gutterBottom
                    textAlign="center"
                  >
                    Somewhere in Mzansi
                    <br />
                    <br />
                  </Typography>
                </Grid>
                <Grid item container justifyContent="center">
                  <Grid item md={5} justifyContent="center">
                    <Map />
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <SocialLinks />
            </Grid>
          </CardContent>
        </Card>
      </article>
      <br />
    </React.Fragment>
  );
};

export default About;
