import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { CountryProps } from "../../pages/Countries";
import theme from "../../theme/theme";

const BackgroundWrapper: React.FC<CountryProps> = (props) => {
  const {
    breakpoints,
    typography: { pxToRem },
  } = theme;

  return (
    <Box>
      <Button
        focusRipple
        variant="text"
        href={props.url}
        sx={{
          "&:hover": {
            backgroundColor: "rgba(0,0,0,0.75)",
            textDecoration: "underline",
          },
          m: 1,
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${props.img})`,
            backgroundSize: "cover",
            minWidth: { minWidth: 276 },
            [breakpoints.up("xl")]: {
              minWidth: 828,
            },
            minHeight: { minHeight: 183 },
            [breakpoints.up("xl")]: {
              minHeight: 549,
            },
          }}
        >
          <Box>
            <Typography
              component="h4"
              variant="h5"
              color="#cccccc"
              gutterBottom
              textAlign="center"
              sx={{
                m: 1,
                fontSize: { fontSize: 24 },
                [breakpoints.up("xl")]: {
                  fontSize: 96,
                },
              }}
            >
              <strong>{props.title}</strong>
            </Typography>
          </Box>
        </Box>
      </Button>
    </Box>
  );
};

export default BackgroundWrapper;
