import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchSingleTerm, fetchTerms } from "../../firebase";
import { SingleTermPayload, TermType } from "../../types/types";

export const getTerms = createAsyncThunk("terms/getTerms", async () => {
  const Terms = await fetchTerms();
  return Terms;
});

export const getSingleTerm = createAsyncThunk(
  "terms/getSingleTerm",
  async (payload: SingleTermPayload) => {
    const Term: any = await fetchSingleTerm(payload.term);
    return Term;
  }
);

export const terms = createSlice({
  name: "terms",
  initialState: {
    terms: [] as TermType[],
    activeTerm: {} as TermType,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTerms.fulfilled, (state, action) => {
      state.terms = action.payload;
    });
    builder.addCase(getSingleTerm.fulfilled, (state, action) => {
      state.activeTerm = action.payload;
    });
  },
});

export default terms;
