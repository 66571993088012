import React, { useRef, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import SunEditor from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";
import "suneditor/dist/css/suneditor.min.css";
import plugins from "suneditor/src/plugins";
import { createTerm } from "../firebase";
import { useAppSelector } from "../redux/hooks/hooks";

const CreateTerm = () => {
  const editor = useRef<SunEditorCore>();
  const user: any = useAppSelector((state) => state.user.user);
  const [state, setState] = useState({
    term: "",
    partOfSpeech: "",
    soundcloudLink: "",
  });

  const [editorContent, setEditorContent] = useState("");

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  const handleChange = (content: string) => {
    setEditorContent(content);
    console.log(editorContent);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = () => {
    createTerm({
        ...state,
        editorContent
    })
  };

  return (
    <React.Fragment>
      <Grid container justifyContent="center">
        <Typography variant="h3" color="primary.main" gutterBottom>
          Create Term
        </Typography>
      </Grid>
      <article>
        <Card
          variant="outlined"
          sx={{
            marginBottom: 10,
            marginX: 20,
            backgroundColor: "secondary.main",
          }}
        >
          <CardContent>
            <Grid container justifyContent="space-between">
              <Grid item xs={10} md={5}>
                <TextField
                  margin="normal"
                  variant="outlined"
                  id="term"
                  placeholder="Term"
                  name="term"
                  type="text"
                  fullWidth
                  autoFocus
                  value={state.term}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={10} md={5}>
                <TextField
                  margin="normal"
                  variant="outlined"
                  id="partOfSpeech"
                  placeholder="Part of speech"
                  name="partOfSpeech"
                  type="text"
                  fullWidth
                  autoFocus
                  value={state.partOfSpeech}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={10} md={5}>
                <TextField
                  margin="normal"
                  variant="outlined"
                  id="soundcloudLink"
                  placeholder="Soundcloud link"
                  name="soundcloudLink"
                  type="text"
                  fullWidth
                  autoFocus
                  value={state.soundcloudLink}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <SunEditor
                onChange={handleChange}
                setOptions={{
                  plugins: plugins,
                  buttonList: [
                    ["undo", "redo"],
                    [
                      ":p-More Paragraph-default.more_paragraph",
                      "font",
                      "fontSize",
                      "formatBlock",
                      "paragraphStyle",
                      "blockquote",
                    ],
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                    ],
                    ["fontColor", "hiliteColor", "textStyle"],
                    ["removeFormat"],
                    ["outdent", "indent"],
                    ["align", "horizontalRule", "list", "lineHeight"],
                    [
                      "-right",
                      ":i-More Misc-default.more_vertical",
                      "fullScreen",
                      "showBlocks",
                      "codeView",
                      "preview",
                      "print",
                      "save",
                      "template",
                    ],
                    ["-right", "image", "video", "audio", "link"],
                  ],
                }}
                getSunEditorInstance={getSunEditorInstance}
              />
            </Grid>
            <Button onClick={() => handleSubmit()}>Submit</Button>
          </CardContent>
        </Card>
      </article>
    </React.Fragment>
  );
};

export default CreateTerm;
