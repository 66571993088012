import { AppBar, Button, Grid, Stack, Toolbar } from "@mui/material";

const Footer = () => {
  return (
    <AppBar
      sx={{
        top: "auto",
        bottom: 0,
        backgroundColor: "info.main",
        color: "primary.main",
        maxHeight: 50,
      }}
    >
      <Toolbar>
        <Grid container>
          <Grid item container justifyContent="center">
            <Stack direction="row">
              <Button variant="text">Powered by Lunar Web Development</Button>
              <Button variant="text" href="mailto:something@somewhere.co.za">
                Info@mzansitaal.co.za
              </Button>
              <Button variant="text" href="/GloLingCorp">
                GloLingCorp
              </Button>
              <Button variant="text" href="/authentication">
                Log In
              </Button>
              <Button variant="text" href="/authentication">
                Sign Up
              </Button>
              <Button variant="text" href="/suggest-term">
                Suggest a Term
              </Button>
              <Button variant="text" href="/terms-of-use">
                Terms of Use
              </Button>
              <Button variant="text">Site Map</Button>
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
