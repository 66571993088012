import * as React from "react";
import { Card, Grid } from "@mui/material";
import ForgotPasswordForm from "../components/forms/ForgotPasswordForm";
import FlagBanner from "../components/ui/FlagBanner";
import { useLocation } from "react-router-dom";
import LoginForm from "../components/forms/LoginForm";
import RegisterForm from "../components/forms/RegisterForm";

const Auth = () => {
  const location = useLocation();

  return (
    <React.Fragment>
      <Grid container justifyContent="center">
        <FlagBanner />
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={10} md={6}>
          <Card>
            {location.pathname === "/log-in" && <LoginForm />}
            {location.pathname === "/sign-up" && <RegisterForm />}
            {location.pathname === "/forgot-password" && <ForgotPasswordForm />}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Auth;
