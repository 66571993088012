import React, { useEffect } from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import theme from "./theme/theme";
import { Route, Routes } from "react-router-dom";
import Terms from "./pages/Terms";
import About from "./pages/About";
import Header from "./components/ui/Header";
import Footer from "./components/ui/Footer";
import Auth from "./pages/Auth";
import Countries from "./pages/Countries";
import GloLingCorp from "./pages/GloLingCorp";
import SingleTerm from "./pages/SingleTerm";
import SuggestATerm from "./pages/SuggestATerm";
import Team from "./pages/Team";
import TermsOfUse from "./pages/TermsOfUse";
import { onAuthStateChanged, auth, getExtraUserInformation } from "./firebase";
import { login } from "./redux/reducers/userSlice";
import { useAppDispatch } from "./redux/hooks/hooks";
import CreateTerm from "./pages/CreateTerm";

function App() {
  const dispatch = useAppDispatch()
  // check at page load if a user is authenticated
  
  useEffect(() => {
    onAuthStateChanged(auth, async (userAuth) => {
      if (userAuth) {
        const values = await getExtraUserInformation(userAuth.uid)

        dispatch(
          login({
            email: userAuth.email,
            uid: userAuth.uid,
            displayName: userAuth.displayName,
            photoUrl: userAuth.photoURL,
            roles: values?.roles,
          })
        );
      }
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Routes>
        <Route path="/" element={<Terms />} />
        <Route path="/about" element={<About />} />
        <Route path="/log-in" element={<Auth />} />
        <Route path="/sign-up" element={<Auth />} />
        <Route path="/forgot-password" element={<Auth />} />
        <Route path="/countries" element={<Countries />} />
        <Route path="/GloLingCorp" element={<GloLingCorp />} />
        <Route path="/terms/:id" element={<SingleTerm />} />
        <Route path="/suggest-term" element={<SuggestATerm />} />
        <Route path="/team" element={<Team />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/create-term" element={<CreateTerm />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
