import * as React from "react";
import {
  AppBar,
  Button,
  Grid,
  IconButton,
  Stack,
  Toolbar,
} from "@mui/material";
import mzanzi_taal_logo from "../../assets/images/mzansi.png";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import { logout } from "../../redux/reducers/userSlice";

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const user: any = useAppSelector((state) => state.user.user);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <AppBar
      position="static"
      color="inherit"
      sx={{ backgroundColor: "secondary.main", color: "primary.main" }}
    >
      <Toolbar>
        <Grid container>
          <Grid item container>
            <Stack direction="row" justifyContent="space-between">
              <IconButton href="/">
                <img alt="Mzanzi_Taal_Logo" src={mzanzi_taal_logo} width="75" />
              </IconButton>
              <Button variant="text" href="/" sx={{ pr: 30 }}>
                The South African Taal Dictionary
              </Button>
              <Button variant="text" href="/about">
                About Us
              </Button>
              <Button variant="text" href="/GloLingCorp">
                GloLingCorp
              </Button>
              <Button variant="text" href="/suggest-term">
                Suggest a Term
              </Button>
              <Button variant="text" href="/team">
                Team
              </Button>
              <Button variant="text" href="/countries">
                Countries
              </Button>
              {user.uid == "" && (
                <Button variant="text" href="/log-in">
                  Log In
                </Button>
              )}
              {user.uid == ""  && (
                <Button variant="text" href="/sign-up">
                  Sign Up
                </Button>
              )}
              {user && user.uid !== "" && user.roles && user.roles.length !== 0 && user.roles.includes("admin") && (
                <Button variant="text" href="/create-term">
                  Create Term
                </Button>
              )}
              {user.uid != "" && (
                <Button variant="text" onClick={() => handleLogout()}>
                  logout
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
