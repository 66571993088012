// import BigTextFont from '../assets/fonts/CosmicSansMSMM.otf';
import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
          main: "#1f2020",
        },
        secondary: {
          main: "#fff",
          light: "#cccccc"
        },
        info : {
            main: "#4158d0"
        }
      },
}

)

export default theme;