import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const Video = ({ embedId }) => (
  <Box
    sx={{
      height: "100%",
      width: "100%",
      position: "relative",
    }}
  >
    <iframe
      width="600"
      height="400"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </Box>
);

Video.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default Video;
