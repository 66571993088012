import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SocialLinks from "../ui/SocialLinks";

const ForgotPasswordForm = () => {
  const [state, setState] = React.useState({
    email: "",
    password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const recaptchaRef: any = React.createRef();
  const onSubmit = (event) => {
    event.preventDefault();
    recaptchaRef.current.execute();
  };

  const commonStyles = {
    bgcolor: "secondary.main",
    pt: 1,
    m: 1,
    width: "40rem",
    height: "6rem",
  };

  return (
    <React.Fragment>
      <form onSubmit={onSubmit}>
        <Grid
          sx={{ flexGrow: 1 }}
          container
          spacing={2}
          justifyContent="center"
        >
          <Card
            variant="outlined"
            sx={{
              marginBottom: 10,
              backgroundColor: "secondary.main",
            }}
          >
            <CardContent>
              <Grid container justifyContent="center">
                <Grid
                  item
                  container
                  justifyContent="center"
                  sx={{ ...commonStyles }}
                >
                  <Typography
                    component="h1"
                    variant="h3"
                    color="primary.main"
                    gutterBottom
                    textAlign="center"
                  >
                    <strong>Lost Password</strong>
                  </Typography>
                </Grid>
              </Grid>
              <br />
              <TextField
                margin="normal"
                variant="outlined"
                id="email"
                label="Email Address"
                name="email"
                type="email"
                fullWidth
                autoFocus
                value={state.email}
                onChange={handleInputChange}
              />
              <Stack direction="column">
                <Grid container item justifyContent="center">
                  <Button variant="outlined" sx={{ m: 2, width: "50%" }}>
                    Get New Password
                  </Button>
                </Grid>
                <Grid container item justifyContent="center">
                  <Button variant="text" color="info">
                    Log In
                  </Button>
                  <Button variant="text" color="info">
                    Register
                  </Button>
                </Grid>
                <SocialLinks />
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </form>
      <br />
    </React.Fragment>
  );
};

export default ForgotPasswordForm;
