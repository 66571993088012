import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import FlagBanner from "../components/ui/FlagBanner";
import { useAppDispatch, useAppSelector } from "../redux/hooks/hooks";
import { getSingleTerm } from "../redux/reducers/termsSlice";
import AdSense from "react-adsense";

const SingleTerm = () => {
  const dispatch = useAppDispatch();
  const term = useAppSelector((state) => state.terms.activeTerm);
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      dispatch(getSingleTerm({ term: params.id }));
    }
  }, [params]);

  return (
    <Fragment>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={7}>
          <FlagBanner notMain />
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container justifyContent={"center"}>
            <AdSense.Google
              client="ca-pub-1901398323502922"
              slot="6262655829"
              format="auto"
              responsive="true"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item display={{ xs: "none", md: "block" }} xs={0} md={2}></Grid>
        <Grid item xs={12} md={7}>
          <Card variant="outlined">
            <CardContent>
              <Typography component="h1" variant="h3">
                {term.term}
              </Typography>
              <Typography variant="subtitle1">{term.partOfSpeech}</Typography>
              <div
                dangerouslySetInnerHTML={{ __html: term.editorContent }}
              ></div>
            </CardContent>
          </Card>
        </Grid>
        <Grid display={{ xs: "none", md: "block" }} item xs={0} md={2}></Grid>
      </Grid>
    </Fragment>
  );
};

export default SingleTerm;
