import * as React from "react";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import FlagBanner from "../components/ui/FlagBanner";
import SocialLinks from "../components/ui/SocialLinks";

const TermsOfUse = () => {
  const commonStyles = {
    bgcolor: "secondary.main",
    pt: 1,
    m: 1,
    border: 1,
    width: "25rem",
    height: "6rem",
  };

  return (
    <React.Fragment>
      <Grid container justifyContent={"center"}>
        <FlagBanner />
      </Grid>
      <Card
        variant="outlined"
        sx={{ marginX: 5, backgroundColor: "secondary.main" }}
      >
        <CardContent>
          <Grid container justifyContent="center">
            <Grid
              item
              container
              justifyContent="center"
              sx={{ ...commonStyles, borderColor: "secondary.light" }}
            >
              <Typography
                sx={{
                  fontStyle: "italic",
                }}
                component="h1"
                variant="h3"
                color="primary.main"
                gutterBottom
                textAlign="center"
              >
                <strong>Terms of use</strong>
              </Typography>
            </Grid>
          </Grid>
          <br />

          <Typography
            gutterBottom
            variant="subtitle1"
            color="primary.main"
            textAlign="center"
          >
            Date of Submission: December 14, 2021
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary.main"
            gutterBottom
            textAlign="center"
          >
            <strong>Rights and Responsibilities</strong>
          </Typography>
          <Typography variant="body1" color="primary.main" textAlign="center">
            This terms of use that governs our relationship with users. By using
            <Button
              sx={{ color: "info.main", typography: "body2" }}
              variant="text"
            >
              Mzansi
            </Button>
            Taal,
          </Typography>
          <Typography variant="body1" color="primary.main" textAlign="center">
            you agree to this Statement, which will be updated overtime, it is
            the users’
          </Typography>
          <Typography variant="body1" color="primary.main" textAlign="center">
            responsibilities to keep updated with the latest terms of use.
          </Typography>
          <br />
          <Typography
            variant="subtitle1"
            color="primary.main"
            gutterBottom
            textAlign="center"
          >
            <strong>Privacy</strong>
          </Typography>
          <Typography variant="body1" color="primary.main" textAlign="center">
            Your privacy is very important to us. We will not share any of your
            information with
          </Typography>
          <Typography variant="body1" color="primary.main" textAlign="center">
            third parties that are not working with or in partnership with
            Mzansi Taal.
          </Typography>
          <br />
          <Typography
            variant="subtitle1"
            color="primary.main"
            gutterBottom
            textAlign="center"
          >
            <strong>Sharing Your Content and Information</strong>
          </Typography>
          <Typography variant="body1" color="primary.main" textAlign="center">
            All of the content and information that you post on Mzansi Taal
            platforms is
          </Typography>
          <Typography variant="body1" color="primary.main" textAlign="center">
            owned by Mzansi Taal, and you in addition:
          </Typography>
          <Typography
            component="ol"
            variant="body1"
            color="primary.main"
            textAlign="center"
            sx={{ marginX: 35 }}
          >
            <li>
              For content that is covered by intellectual property rights, like
              photos and
              <br />
              videos (IP content), you grant us the following permission, a
              non-exclusive,
              <br />
              transferable, sub-licensable, royalty-free, worldwide license to
              use any IP
              <br />
              content that you post on or in connection with Mzansi Taal. This
              IP License
              <br />
              ends when Mzansi Taal delete your IP content, unless your content
              has been
              <br />
              shared with others, and they have not deleted it.
            </li>
            <li>
              IP content is deleted in a manner similar to emptying the recycle
              bin on a
              <br />
              computer. However, you understand that removed content may persist
              in
              <br />
              backup copies for a reasonable period of time (but will not be
              available to
              <br />
              others).
            </li>
            <li>
              When you publish content or information on Mzansi Taal it becomes
              public
              <br />
              to all Mzansi Taal users and you agree to associate the content
              posted with
              <br />
              the profile details that you have provided.
              <br />
            </li>
            <li>
              We always appreciate your feedback or other suggestions about
              Mzansi Taal.
              <br />
              We may use your feedback or suggestions without any obligation to
              <br />
              compensate you for them.
            </li>
            <br />
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary.main"
            gutterBottom
            textAlign="center"
          >
            <strong>Safety</strong>
          </Typography>
          <Typography variant="body1" color="primary.main" textAlign="center">
            We do our best to keep Mzansi Taal safe, but we cannot guarantee it.
            In an effort
            <br />
            to do this we require your assistance regarding the following
            comments:
            <br />
          </Typography>
          <br />
          <Typography
            component="ol"
            variant="body1"
            color="primary.main"
            textAlign="center"
            sx={{ marginX: 35 }}
          >
            <li>
              You will not post unauthorized commercial communications (such as
              spam)
              <br />
              on Mzansi Taal.
            </li>
            <li>
              You will not collect users’ content or information, or
              <Button
                sx={{ color: "info.main", typography: "body2" }}
                variant="text"
              >
                otherwise
              </Button>
              access Mzansi
              <br />
              Taal, using automated means (such as harvesting
              <Button
                sx={{ color: "info.main", typography: "body2" }}
                variant="text"
              >
                bots
              </Button>
              , robots, spiders, or
              <br />
              scrapers) without our prior permission.
            </li>
            <li>
              You will not engage in unlawful multi-level marketing, such as a
              pyramid
              <br />
              scheme, on Mzansi Taal.
            </li>
            <li>You will not upload viruses or other malicious code.</li>
            <li>
              You will not solicit login information or access an account
              belonging to
              <br />
              someone else.
            </li>
            <li>You will not bully, intimidate, or harass any user.</li>
            <li>
              You will not post content that: is hate speech, threatening, or
              pornographic;
              <br />
              incites violence; or contains nudity or graphic or gratuitous
              violence.
            </li>
            <li>
              You will not develop or operate a third-party application
              containing alcohol-
              <br />
              related, dating or other mature content (including advertisements)
              without
              <br />
              appropriate age-based restrictions.
            </li>
            <li>
              You will not use Mzansi Taal to do anything unlawful, misleading,
              malicious,
              <br />
              or discriminatory.
            </li>
            <li>
              You will not do anything that could disable, overburden, or impair
              the proper
              <br />
              working or appearance of Mzansi Taal, such as a denial of service
              attack or
              <br />
              interference with page rendering or other Mzansi Taal
              functionality.
            </li>
            <li>
              You will not facilitate or encourage any violations of this
              Statement or our
              <br />
              policies.
            </li>
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary.main"
            gutterBottom
            textAlign="center"
          >
            <br />
            <strong>Registration and Account Security</strong>
          </Typography>
          <Typography variant="body1" color="primary.main" textAlign="center">
            Mzansi Taal users provide their real names and information, and we
            need your
            <br />
            help to keep it that way. Here are some commitments you make to us
            relating to
            <br />
            registering and maintaining the security of your account:
          </Typography>
          <Typography
            component="ol"
            variant="body1"
            color="primary.main"
            textAlign="center"
            sx={{ marginX: 35 }}
          >
            <li>
              You will not provide any false personal information on Mzansi
              Taal, or create
              <br />
              an account for anyone other than yourself without permission.
            </li>
            <li>You will not create more than one personal account.</li>
            <li>
              If we disable your account, you will not create another one
              without our
              <br />
              permission.
            </li>
            <li>You will not use Mzansi Taal if you are under 12.</li>
            <li>
              You will keep your contact information accurate and up-to-date.
            </li>
            <li>
              You will not share your password (or in the case of developers,
              your secret
              <br />
              key), let anyone else access your account, or do anything else
              that might
              <br />
              jeopardize the security of your account.
            </li>
            <li>
              You will not transfer your account to anyone without first getting
              our written
              <br />
              permission.
            </li>
            <li>
              If you select a username or similar identifier for your account or
              Page, we
              <br />
              reserve the right to remove or reclaim it if we believe it is
              appropriate (such
              <br />
              as when a trademark owner complains about a username that does not
              <br />
              closely relate to a user’s actual name).
            </li>
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary.main"
            gutterBottom
            textAlign="center"
          >
            <br />
            <strong>Protecting Other People’s Rights</strong>
          </Typography>
          <Typography variant="body1" color="primary.main" textAlign="center">
            We respect other people’s rights, and expect you to do the same.
            <br />
          </Typography>
          <br />
          <Typography
            component="ol"
            variant="body1"
            color="primary.main"
            textAlign="center"
            sx={{ marginX: 35 }}
          >
            <li>
              You will not post content or take any action on Mzansi Taal that
              infringes or
              <br />
              violates someone else’s rights or otherwise violates the law.
            </li>
            <li>
              We can remove any content or information you post on Mzansi Taal
              if we
              <br />
              believe that it violates this Statement or our policies.
            </li>
            <li>
              If we remove your content for infringing someone else’s copyright,
              and you
              <br />
              believe we removed it by mistake, we will provide you with an
              opportunity to
              <br />
              appeal.
            </li>
            <li>
              If you repeatedly infringe other people’s intellectual property
              rights, we will
              <br />
              disable your account if need be.
            </li>
            <li>
              You will not use our copyrights or Trademarks or any confusingly
              similar
              <br />
              marks, except as expressly permitted by Mzansi Taal with our prior
              written
              <br />
              permission.
            </li>
            <li>
              If you collect information from users, you will: obtain their
              consent, make it
              <br />
              clear you (and not Mzansi Taal) are the one collecting their
              information, and
              <br />
              post a privacy policy explaining what information you collect and
              how you
              <br />
              will use it.
            </li>
            <li>
              You will not post anyone’s identification documents or sensitive
              financial
              <br />
              information on Mzansi Taal.
            </li>
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary.main"
            gutterBottom
            textAlign="center"
          >
            <br />
            <strong>Mobile and Other Devices</strong>
          </Typography>
          <Typography
            component="ol"
            variant="body1"
            color="primary.main"
            textAlign="center"
            sx={{ marginX: 35 }}
          >
            <li>
              We currently provide our mobile services for free, but please be
              aware that
              <br />
              your carrier’s normal rates and fees, such as text messaging,
              email and data
              <br />
              charges, will still apply.
            </li>
            <li>
              You provide consent and all rights necessary to enable users to
              sync
              <br />
              (including through an application) their devices with any
              information that is
              <br />
              visible to them on Mzansi Taal.
            </li>
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary.main"
            gutterBottom
            textAlign="center"
          >
            <br />
            <strong>
              About Advertisements and Other Commercial Content Served or
              Enhanced
              <br />
              by Mzansi Taal
            </strong>
          </Typography>
          <Typography variant="body1" color="primary.main" textAlign="center">
            Our goal is to deliver advertising and other commercial or sponsored
            content that
            <br />
            is valuable to our users and advertisers. In order to help us do
            that, you agree to
            <br />
            the following:
          </Typography>
          <br />
          <Typography
            component="ol"
            variant="body1"
            color="primary.main"
            textAlign="center"
            sx={{ marginX: 35 }}
          >
            <li>
              You give us permission to use your name, profile picture, content,
              and
              <br />
              information in connection with commercial, sponsored, or related
              content.
              <br />
              This means, for example, that you permit a business or other
              entity to pay us
              <br />
              to display your name and/or profile picture with your content or
              information,
              <br />
              without any compensation to you.
            </li>
            <li>
              We do not give your content or information to advertisers without
              your
              <br />
              consent.
            </li>
            <li>
              You understand that we may not always identify paid services and
              <br />
              communications as such.
            </li>
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary.main"
            gutterBottom
            textAlign="center"
          >
            <br />
            <strong>Special Provisions Applicable to Software</strong>
          </Typography>
          <Typography
            component="ol"
            variant="body1"
            color="primary.main"
            textAlign="center"
            sx={{ marginX: 35 }}
          >
            <li>
              If you download or use our software, such as a stand-alone
              software
              <br />
              product, an app, or a browser plugin, you agree that from time to
              time, the
              <br />
              software may download and install upgrades, updates and additional
              <br />
              features from us in order to improve, enhance, and further develop
              the
              <br />
              software.
            </li>
            <li>
              You will not modify, create derivative works of, decompile, or
              otherwise
              <br />
              attempt to extract source code from us, unless you are expressly
              permitted
              <br />
              to do so under an open source license, or we give you express
              written
              <br />
              permission.
            </li>
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary.main"
            gutterBottom
            textAlign="center"
          >
            <br />
            <strong>Termination</strong>
          </Typography>
          <Typography variant="body1" color="primary.main" textAlign="center">
            If you violate the letter or spirit of this Statement, or otherwise
            create risk or
            <br />
            possible legal exposure for us, we can stop providing all or part of
            Mzansi Taal to
            <br />
            you. We will notify you by email or at the next time you attempt to
            access your
            <br />
            account. You may also delete your account or disable your
            application at any time.
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary.main"
            gutterBottom
            textAlign="center"
          >
            <br />
            <strong>Disputes</strong>
          </Typography>
          <Typography
            component="ol"
            variant="body1"
            color="primary.main"
            textAlign="center"
            sx={{ marginX: 35 }}
          >
            <li>
              You will resolve any claim, cause of action or dispute (claim) you
              have with us
              <br />
              arising out of or relating to this Statement or Mzansi Taal
              exclusively in the
              <br />
              Republic of South Africa (RSA), and you agree to submit to the
              personal
              <br />
              jurisdiction of such courts for the purpose of litigating all such
              claims. The
              <br />
              laws of RSA will govern this Statement, as well as any claim that
              might arise
              <br />
              between you and us, without regard to conflict of law provisions.
            </li>
            <li>
              If anyone brings a claim against us related to your actions,
              content or
              <br />
              information on Mzansi Taal, you will indemnify and hold us
              harmless from
              <br />
              and against all{" "}
              <Button
                sx={{ color: "info.main", typography: "body2" }}
                variant="text"
              >
                damages,
              </Button>
              losses, and expenses of any kind (including
              <br />
              reasonable legal fees and costs) related to such claim. Although
              we provide
              <br />
              rules for user conduct, we do not control or direct users’ actions
              on Mzansi
              <br />
              Taal and are not responsible for the content or information users
              transmit or
              <br />
              share on Mzansi Taal. We are not responsible for any offensive,
              <br />
              inappropriate, obscene, unlawful or otherwise objectionable
              content or
              <br />
              information you may encounter on Mzansi Taal. We are not
              responsible for
              <br />
              the conduct, whether online or offline, of any user of Mzansi
              Taal.
            </li>
            <li>
              WE TRY TO KEEP MZANSI TAAL UP, BUG-FREE, AND SAFE, BUT YOU USE IT
              <br />
              AT YOUR OWN RISK. WE ARE PROVIDING MZANSI TAAL AS IS WITHOUT
              <br />
              ANY EXPRESS OR IMPLIED WARRANTIES INCLUDING, BUT NOT LIMITED TO,
              <br />
              IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              <br />
              PURPOSE, AND NON-INFRINGEMENT. WE DO NOT GUARANTEE THAT
              <br />
              MZANSI TAAL WILL ALWAYS BE SAFE, SECURE OR ERROR-FREE OR THAT
              <br />
              MZANSI TAAL WILL ALWAYS FUNCTION WITHOUT DISRUPTIONS, DELAYS
              <br />
              OR IMPERFECTIONS. MZANSI TAAL IS NOT RESPONSIBLE FOR THE ACTIONS,
              <br />
              CONTENT, INFORMATION, OR DATA OF THIRD PARTIES, AND YOU RELEASE
              <br />
              US, OUR DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY
              <br />
              CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN
              <br />
              ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH
              <br />
              THIRD PARTIES. WE WILL NOT BE LIABLE TO YOU FOR ANY LOST PROFITS
              OR
              <br />
              OTHER CONSEQUENTIAL, SPECIAL, INDIRECT, OR INCIDENTAL DAMAGES
              <br />
              ARISING OUT OF OR IN CONNECTION WITH THIS STATEMENT OR MZANSI
              <br />
              TAAL, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
              <br />
              DAMAGES. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR
              <br />
              EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES,
              <br />
              SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN
              <br />
              SUCH CASES, MZANSI TAAL’S LIABILITY WILL BE LIMITED TO THE FULLEST
              <br />
              EXTENT PERMITTED BY APPLICABLE LAW.
            </li>
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary.main"
            gutterBottom
            textAlign="center"
          >
            <br />
            <strong>
              Special Provisions Applicable to Users Outside the Republic of
              South Africa
            </strong>
          </Typography>
          <Typography variant="body1" color="primary.main" textAlign="center">
            We strive to create a global community with consistent standards for
            everyone,
            <br />
            but we also strive to respect local laws. The following provisions
            apply to users and
            <br />
            non-users who interact with Mzansi Taal outside the RSA:
          </Typography>
          <Typography
            component="ol"
            variant="body1"
            color="primary.main"
            textAlign="center"
            sx={{ marginX: 35 }}
          >
            <li>
              You consent to having your personal data transferred to and
              processed in
              <br />
              where in the RSA.
            </li>
            <li>
              If you are located in a country embargoed by the RSA, you will not
              engage in
              <br />
              commercial activities on Mzansi Taal (such as advertising or
              payments) or
              <br />
              operate a Platform application or website. You will not use Mzansi
              Taal if you
              <br />
              are prohibited from receiving products, services, or software
              originating from
              <br />
              the RSA.
            </li>
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary.main"
            gutterBottom
            textAlign="center"
          >
            <br />
            <strong>Definitions</strong>
          </Typography>
          <Typography
            component="ol"
            variant="body1"
            color="primary.main"
            textAlign="center"
            sx={{ marginX: 35 }}
          >
            <li>
              By “Mzansi Taal” we mean the features and services we make
              available,
              <br />
              including through (a) our website at www.mzansitaal.co.za and any
              other
              <br />
              Mzansi Taal branded or co-branded websites (including sub-domains,
              <br />
              international versions, widgets, and mobile versions); (b) our
              Platform; (c)
              <br />
              social plugins such as the Like
              <Button
                sx={{ color: "info.main", typography: "body2" }}
                variant="text"
              >
                button,
              </Button>
              the Share button and other similar
              <br />
              offerings; and (d) other media, brands, products, services,
              software (such as a
              <br />
              toolbar), devices, or networks now existing or later developed.
              Mzansi Taal
              <br />
              reserves the right to designate, in its sole discretion, that
              certain of our
              <br />
              brands, products, or services are governed by separate terms and
              not this
              <br />
              SRR.
            </li>
            <li>
              By “Platform” we mean a set of APIs and services (such as content)
              that
              <br />
              enable others, including application developers and website
              operators, to
              <br />
              retrieve data from Mzansi Taal or provide data to us.
              <br />
            </li>
            <li>
              By “information” we mean facts and other information about you,
              including
              <br />
              actions taken by users and non-users who interact with Mzansi
              Taal.
            </li>
            <li>
              By “content” we mean anything you or other users post, provide or
              share
              <br />
              using Mzansi Taal Services.
            </li>
            <li>
              By “data” or “user data” or “user’s data” we mean any data,
              including a user’s
              <br />
              content or information that you or third parties can retrieve from
              Mzansi Taal
              <br />
              or provide to Mzansi Taal through Platform.
              <br />
            </li>
            <li>
              By “post” we mean post on Mzansi Taal or otherwise make available
              by using
              <br />
              Mzansi Taal.
            </li>
            <li>
              By “use” we mean use, run, copy, publicly perform or display,
              distribute,
              <br />
              modify, translate, and create derivative works of.
            </li>
            <li>
              By “application” we mean any application or website that uses or
              accesses
              <br />
              Platform, as well as anything else that receives or has received
              data from us.
              <br />
              If you no longer access Platform but have not deleted all data
              from us, the
              <br />
              term application will apply until you delete the data.
            </li>
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary.main"
            gutterBottom
            textAlign="center"
          >
            <br />
            <strong>Other</strong>
          </Typography>
          <Typography
            component="ol"
            variant="body1"
            color="primary.main"
            textAlign="center"
            sx={{ marginX: 35 }}
          >
            <li>
              This Statement makes up the entire agreement between the parties
              <br />
              regarding Mzansi Taal, and supersedes any prior agreements.
            </li>
            <li>
              If any portion of this Statement is found to be unenforceable, the
              remaining
              <br />
              portion will remain in full force and effect.
            </li>
            <li>
              If we fail to enforce any of this Statement, it will not be
              considered a waiver.
            </li>
            <li>
              Any amendment to or waiver of this Statement must be made in
              writing and
              <br />
              signed by us.
            </li>
            <li>
              You will not transfer any of your rights or obligations under this
              Statement to
              <br />
              anyone else without our consent.
            </li>
            <li>
              All of our rights and obligations under this Statement are freely
              assignable
              <br />
              by us in connection with a merger, acquisition, or sale of assets,
              or by
              <br />
              operation of law or otherwise.
            </li>
            <li>
              Nothing in this Statement shall prevent us from complying with the
              law.
            </li>
            <li>
              This Statement does not confer any third party beneficiary rights.
            </li>
            <li>We reserve all rights not expressly granted to you.</li>
            <li>
              You will comply with all applicable laws when using or accessing
              Mzansi Taal.
            </li>
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary.main"
            gutterBottom
            textAlign="center"
          >
            <br />
            <strong>
              By using or accessing Mzansi Taal Services, you agree that we can
              collect and
              <br />
              use such content and information.
            </strong>
          </Typography>
          <Typography variant="body1" color="primary.main" textAlign="center">
            To access the Statement of Rights and Responsibilities in several
            different
            <br />
            languages, it is your responsibility to translate it yourself.
            <br />
            <br />
            If anything is not clear you may contact info@www.mzansitaal.co.za.
          </Typography>
          <SocialLinks />
          <br />
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default TermsOfUse;
