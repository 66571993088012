import React, {useEffect} from "react";
import {
  Button,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SocialLinks from "../ui/SocialLinks";
import { useAppDispatch } from "../../redux/hooks/hooks";
import { login, logout } from "../../redux/reducers/userSlice";
import {
  auth,
  createUserWithEmailAndPassword,
  updateProfile,
  createUserProps,
  onAuthStateChanged,
  getExtraUserInformation
} from "../../firebase";
import UploadProfilePicture from "../ui/UploadProfilePicture";
import { useNavigate } from "react-router-dom";

const RegisterForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    email: "",
    password: "",
    confirmPassword: "",
    displayName: "",
    profilePicture: "",
  });

   // check at page load if a user is authenticated
   useEffect(() => {
    onAuthStateChanged(auth, async (userAuth) => {
      if (userAuth) {
        const values = await getExtraUserInformation(userAuth.uid)

        dispatch(
          login({
            email: userAuth.email,
            uid: userAuth.uid,
            displayName: userAuth.displayName,
            photoUrl: userAuth.photoURL,
            roles: values?.roles,
          })
        );
      }
    });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };


  const handleSubmit = () => {
    if (!state.displayName) {
      return alert("Please enter a full name");
    }

    // Create a new user with Firebase
    createUserWithEmailAndPassword(auth, state.email, state.password)
      .then(
        (
          userAuth // Update the newly created user with a display name and a picture
        ) =>
          updateProfile(userAuth.user, {
            displayName: state.displayName,
            photoURL: state.profilePicture,
          })
            .then(async (res) => {
              const userProps = await createUserProps(userAuth.user.uid, ['user'])

              dispatch(
                login({
                  email: userAuth.user.email,
                  uid: userAuth.user.uid,
                  displayName: state.displayName,
                  photoUrl: state.profilePicture,
                  roles: ['user']
                })
              );
            }).then(() => {
              navigate("/", { replace: true });
            })
            .catch((error) => {
              console.log("user not updated", error);
            })
      )
      .catch((err) => {
        alert(err);
      });
  };

  const setProfilePicture = (url: string) => {
    setState({ ...state, profilePicture: url });
  }

  return (
    <React.Fragment>
      <Grid sx={{ flexGrow: 1 }} container justifyContent="center">
        <CardContent>
          <Grid container justifyContent="center">
            <Grid item container justifyContent="center">
              <Typography
                component="h1"
                variant="h3"
                color="primary.main"
                gutterBottom
                textAlign="center"
              >
                <strong>Register</strong>
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Grid container justifyContent="space-between">
            <Grid item xs={10} md={5}>
              <TextField
                margin="normal"
                variant="outlined"
                id="email"
                placeholder="Email Address"
                name="email"
                type="email"
                fullWidth
                autoFocus
                style={{ backgroundColor: "white" }}
                value={state.email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <TextField
                margin="normal"
                variant="outlined"
                id="displayName"
                placeholder="Username"
                name="displayName"
                type="text"
                fullWidth
                autoFocus
                style={{ backgroundColor: "white" }}
                value={state.displayName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <TextField
                margin="normal"
                variant="outlined"
                id="password"
                placeholder="Password"
                name="password"
                type="password"
                fullWidth
                style={{ backgroundColor: "white" }}
                value={state.password}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <TextField
                margin="normal"
                variant="outlined"
                id="confirmPassword"
                placeholder="Confirm password"
                name="confirmPassword"
                type="password"
                fullWidth
                style={{ backgroundColor: "white" }}
                value={state.confirmPassword}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid item xs={10} md={12}>
            <UploadProfilePicture handleSetProfilePicture={setProfilePicture} />
          </Grid>
          <Stack direction="column">
            <Typography
              variant="subtitle2"
              color="primary.main"
              gutterBottom
              textAlign="center"
              sx={{ pt: 1, fontWeight: "light" }}
            >
              Registration confirmation will be emailed to you.
            </Typography>
            <Grid container item justifyContent="center">
              <Button
                variant="outlined"
                onClick={() => handleSubmit()}
                sx={{ m: 2, width: "50%" }}
              >
                Register
              </Button>
            </Grid>
            <Grid container item justifyContent="center">
              <Button variant="text" color="info">
                Forgot Password
              </Button>
              <Button variant="text" color="info">
                Log In
              </Button>
            </Grid>
            <SocialLinks />
          </Stack>
        </CardContent>
      </Grid>
      <br />
    </React.Fragment>
  );
};

export default RegisterForm;
