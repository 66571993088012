import { createSlice } from '@reduxjs/toolkit';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';

const defaultUser = {
    email: "",
    displayName: "",
    photoURL: "",
    uid: "",
    roles: []
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: {
        email: "",
        displayName: "",
        photoURL: "",
        uid: "",
        roles: []
    },
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      signOut(auth)
      state.user = defaultUser;
    },
  },
});

export const { login, logout } = userSlice.actions;

// selectors
export const selectUser = (state) => state.user.user;

export default userSlice.reducer;